html,
body {
  margin: 0;
  height: 100%;
}

#root {
  height: 100%;
}




.plainLink {
  color: black;
  text-decoration: none;
}

.otp_element {
  width: 2rem !important;
  height: 2rem;
}

.box {
  z-index: 999;
  position: absolute;
  width: 35vw;
  flex-direction: column;
  display: flex;
  align-items: center;
  height: unset;
  background-color: #ffffff;
  border: 1px solid lightgray;
  top: 10rem;
  left: 30rem;
}


