.container{
    display: grid;
}

.card{
    height: 200px;
    background-color: #fff;
    border: 1px solid#e9be6a;
    border-radius: 5px;
    width: 200px;
    margin: 5px;
}

hr {
    background-color: #e4e4e4 !important;
    color: #e4e4e4 !important;
    border: solid 0.5px #e4e4e4 !important;
    height: 0.5px !important;
    width: 100%;
  }
