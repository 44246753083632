.doxContainer{
    display:flex;
    align-items:flex-start;
    justify-content:flex-start;
    background-color: #ffffff;
}

.images{
    margin-right: 10px;
    object-fit:cover;
    height: 150px;
    width: 100%;
}