.upload__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
}

.drop-file-input {
    position: relative;
    width: 90%;
    height: 200px;
    border: 1px dashed gray;
    border-radius: 5px;
    background-color: #f9f9fb;
    display: flex;
    align-items: center;
    justify-content: center;

}

.drop-file-input input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.drop-file-input:hover,
.drop-file-input.dragover {
    opacity: 0.6;
}

.drop-file-input__label {
    text-align: center;
    color: #a3a6ad;
    font-weight: 600;
    padding: 10px;
}

.drop-file-input__label img {
    width: 100px;
}

.drop-file-preview__item{
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
}
.drop-file-preview__item img{
    height: 50px;
    margin-left: 20px;
}
.drop-file-preview__item p{
    font-size: 15px;
    font-weight: bold;
}
.drop-file-preview__item__info{
    width: 85%;
}
.drop-file-preview__item__info p{
    margin: 5px;
    font-size: 17px;
    font-weight: bold;
}


.button{
    width: 20rem;
    padding: 0.7rem;
    border-radius: 15px;
    background-color: #2091df;
    border: none;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
}